import './Footer.css';

function Footer() {
    return (
        <div className='line'>

            <h6 className='text-center footer-text'>
                ©  2023. All right reserved by Pangea Production.
            </h6>
        </div>

    )
}

export default Footer